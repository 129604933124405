<template>
    <div class="reviewCard">
        <div class="reviewMeta">
            <div class="reviewUserInfo">
                <div class="reviewUserImage" v-if="review.profile_photo_url !== null">
                    <img :src="review.profile_photo_url" :alt="getAltText(review)" :title="getAltText(review)" />
                </div>
                <div class="reviewUserDetails">
                    {{ review.author_name }}
                    <div class="reviewStarRating">
                        <Stars :size="12" :total="5" :filled="review.rating" /> &nbsp;&mdash; <small>{{ review.rating }} of 5</small>
                    </div>
                </div>
            </div>
            <div class="reviewSource">
                <img src="../../images/google_g_icon_download.png" alt="" title="" />
            </div>
        </div>
        <div class="reviewContent">
            <p>{{ review.text }}</p>
        </div>
    </div>
</template>

<script>
import { mapState } from 'pinia';

import { reviewsStore } from "../stores/reviews.js";

import Stars from "../components/Stars.vue";

export default {
    name: "ReviewCard",
    components:{
        Stars
    },
    props: {
        review: {
            type: Object,
            required: true,
            default: null
        }
    },
    computed: {
        ...mapState(reviewsStore, {
            rating: store => store.rating,
            reviewsLoaded: store => store.flags.reviewsLoaded
        }),

    },
    methods: {
        loadSlider: function () {

        },
        getAltText: function (review) {
            return 'Review from ' + this.review.author_name;
        }
    }
};
</script>

