<template>
    <p class="oopsieMsg" style="text-align: center; margin:21px 0;">{{ msg }}</p>
</template>

<script>
export default {
  name: "NothingFoundMessage",
  props: {
    msg:{
        type: String,
        required: true,
        default: ''
    }
  }
};
</script>

<style></style>
