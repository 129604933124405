<template>
    <h6>Google Reviews</h6>
    <ReviewStatistics :skeleton="!reviewsLoaded" />
    <ReviewSlider v-if="reviewsLoaded" />
</template>

<script>
import { mapState } from 'pinia';

import { reviewsStore } from "../stores/reviews.js";
import { globalStore }  from "../stores/global.js";

import ReviewStatistics from "../components/ReviewStatistics.vue";
import ReviewSlider     from "../components/ReviewSlider.vue";

export default {
    name: "ReviewsApp",
    components: {
        ReviewStatistics,
        ReviewSlider
    },
    mounted() {
        const rs = reviewsStore();
        rs.loadReviewData();
    },
    computed: {
        ...mapState(reviewsStore, {
            rating: store => store.rating,
            reviewsLoaded: store => store.flags.reviewsLoaded
        }),
        ...mapState(globalStore, {
            uid: store => store.uid,
            isUserAuthenticated: store => store.isUserAuthenticated,
            siteKey: store => store.siteKey
        })
    }
};
</script>

<style>
</style>
