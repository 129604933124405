<template>

  <div :class="getListingClasses">
    <a :href="getListingURL" :data-listing-id="listingData.id" :data-listing-type="listingData.propertyType">
      <picture class="listingImages" v-html="getMainImage"></picture>
      <div class="listingOverlay">
        <div class="listingPriceBox">
          <div class="listingPrice">
            <Price :price="listingData.price.listPrice" />
          </div>
          <div class="listingActions">
            <ul>
              <li class="actionSaveProperty" v-if="favoritesEnabled && !isFavoriteList">
                <FavoriteWishlist :showSubtext="false" :listingID="listingData.id" :isListingFavorited="listingData.favorited" />
              </li>
              <li class="actionContactAgent">
                <a :href="getContactEmail" target="_blank" :title="getContactTitle"><span class="material-symbols-outlined md-24 md-light">mail</span></a>
              </li>
              <li class="actionSeeOnMap">
                <a href="#" :title="getMapDetailTitle"><span class="material-symbols-outlined md-24 md-light">map</span></a>
              </li>
            </ul>
          </div>
        </div>
        <div class="listingDetails">
          <address class="listingAddress" v-html="getFullAddress"></address>
          <div class="listingAmenities flexed" v-html="getAmenitiesString"></div>
        </div>
      </div>
    </a>
  </div>
</template>

<script>
import Price from "../components/Price.vue";
import FavoriteWishlist from '../components/FavoriteWishlist.vue';

import { globalStore } from "../stores/global";
import { mapState } from 'pinia';

export default {
  name: "ListingCard",
  components: {
    Price,
    FavoriteWishlist
  },
  props: {
    listingData: {
      type: Object,
      required: true,
    },
    userLoggedIn: {
      type: Boolean,
      default: false,
    },
    isFavoriteList:{
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {};
  },
  methods: {},
  computed: {
    ...mapState(globalStore, {
      favoritesEnabled: function(store){        
        return store.favoritesEnabled
      }
    }),
    getWishlistTitle: function () {
      return "Add " + this.listingData.address.fullAddress + " to your saved properties.";
    },
    getContactTitle: function () {
      return "Contact Ashley Starkey about " + this.listingData.address.fullAddress;
    },
    getMapDetailTitle: function () {
      return "See " + this.listingData.address.fullAddress + " on a local map.";
    },
    getFullAddress: function () {
      return this.listingData.address.fullAddress;
    },
    getAmenitiesString: function () {
      const bathsTotal = (this.listingData.baths.full + (this.listingData.baths.half * .5));
      const bedsTotal  = this.listingData.beds;
      const sqft       = this.listingData.sqft;

      return (
        '<span class="material-symbols-outlined md-24 md-light">bed</span> <span>' +
        bedsTotal +
        ' bdrm </span><span class="material-symbols-outlined md-24 md-light">bathtub</span> <span>' +
        bathsTotal +
        ' bthrm </span> <span class="material-symbols-outlined">square_foot</span> <span> ' +
        sqft +
        " sq. Ft</div>"
      );
    },
    getContactEmail: function(){
      return 'mailto:' + this.listingData.contact.email + '?subject=' + this.listingData.address.fullAddress + '(' + this.listingData.id + ')';
    },
    getMainImage: function(){
      let img = '';
      const data = this.listingData;
      const imgData = this.listingData.photography.photoData[0];
      
      if(imgData && 'url' in imgData){
        img = '<img src="' + imgData.url + '" alt="' + imgData.desc + '" title="' + imgData.desc + '" />';
      } else {
        img = '<img src="' + listings.templateDir + '/assets/images/noImagePlaceholder.png" alt="No Images available" title="No Images available" style="height:115%;" />'
      }

      return img;
    },
    getListingURL: function () {
      return "/listing?listingID=" + this.listingData.id;
    },
    getListingClasses: function(){
      let classes = '';

      classes = this.listingData.propertyType + ' ';

      return classes;
    }
  },
};
</script>
