<template>
    {{ getListingPrice() }}
</template>

<script>

export default {
  name: "Price",
  props: {
    price:{
        type: Number,
        required: true
    }
  },
  methods: {
    getListingPrice(){
        const priceStr = new Number(this.price);
        const formatter = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 0 });
        return formatter.format(priceStr)
    },
  }
};
</script>
