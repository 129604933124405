<template>
  <ul class="container flexed homepageListingGrid alignTop" v-if="listingsLoaded && listings != null" >
    <li v-for="listing in listings" class="listingCard">
      <ListingCard :listingData="listing" :hasQuickview="true" />
    </li>
  </ul>
  <GlobalLoader v-if="!listingsLoaded" />

  <NothingFoundMessage :msg="noListingsFoundMsg" v-if="listingsLoaded && listings === null" />
</template>

<script>
import { mapState } from "pinia";

import { homepageListingStore } from "../stores/homepageListings";
import { homeStore } from "../stores/home";

import ListingCard  from "../components/ListingCard.vue";
import GlobalLoader from "../components/GlobalLoader.vue";
import NothingFoundMessage from "../components/NothingFoundMessage.vue";

export default {
  name: "HomepageListingsApp",
  components: {
    ListingCard,
    GlobalLoader,
    NothingFoundMessage
  },
  data() {
    return homepageListingStore();
  },
  mounted(){
    const homepageStore = homepageListingStore();
    homepageStore.getActiveListings(this.maxListings);
  },
  computed: {
    ...mapState(homeStore, {
      maxListings: store => store.maxActiveListings,
    })
  }
};
</script>

<style></style>
