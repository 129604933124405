import { defineStore } from 'pinia';

export const reviewsStore = defineStore('reviewsStore', {
    state: () => {
        return {
            flags:{
                reviewsLoaded: false
            },
            ratings: {
                total: 0,
                rating: 0,
                fiveStar: 0,
                fourStar: 0,
                threeStar: 0,
                twoStar: 0,
                oneStar: 0       
            },
            reviewData:[]
        }
    },
    actions: {
        loadReviewData: function (cb) {
            const self = this;
            const url  = home.ajaxUrl;

            let data = {
                action: 'get_reviews',
                nonce: home.nonce
            }

            // Make request to backend
            $.ajax({
                method: 'POST',
                url: url,
                data: data,
            }).then(function (resp) {
                const response = JSON.parse(resp);
                
                if (response.status) {
                    const ratings = response.data.ratings;

                    self.$patch({
                        flags:{
                            reviewsLoaded: true
                        },
                        ratings: {
                            rating: ratings.rating,
                            total: ratings.total,
                            fiveStar: ratings.fiveStar,
                            fourStar: ratings.fourStar,
                            threeStar: ratings.threeStar,
                            twoStar: ratings.twoStar,
                            oneStar: ratings.oneStar
                        },
                        reviewData: response.data.reviews
                    });

                    // fire off any callbacks
                    if(typeof cb == 'function'){
                        cb();
                    }
                }
            }).catch(function (err) {
                console.log(err)
            })
        }
    }
});