import { defineStore } from 'pinia';
import { toRaw } from 'vue';



export const listingStore = defineStore('listingsStore', {
  state: () => {
    return {
      noListingsFoundMsg: '',
      flags:{
        showListingsView:true,
        showMapView:false,
        mapViewLoaded: false
      },
      listingsLoaded: false,
      listingViewType: 'GRID',
      listingSortBy: '',
      listingSortingOptionsHTML: '',
      listingsFilters: {
        price: {
          loaded: false,
          mustLoad: false,
          data: [],
          name: 'Price',
          value: '',
          field: 'ListPrice',
          type: "minmax",
          filterActive: true
        },
        sqft: {
          loaded: false,
          mustLoad: false,
          data: [],
          name: 'Square Footage',
          value: '',
          field: 'BuildingAreaTotal',
          type: "minmax",
          filterActive: false
        },
        bedrooms: {
          loaded: false,
          mustLoad: false,
          data: [],
          name: 'Bedrooms',
          value: '',
          field: 'BedroomsTotal',
          type: "minmax",
          filterActive: false
        },
        baths: {
          loaded: false,
          mustLoad: false,
          data: [],
          name: 'Bathrooms',
          value: '',
          field: 'BathroomsFull',
          type: "minmax",
          filterActive: false
        },
        status: {
          loaded: true,
          mustLoad: false,
          data: [
            {
              name: 'Active',
              value: 'Active',
              type: 'StandardStatus'
            },
            {
              name: 'Active Under Contract',
              value: 'Active Under Contract',
              type: 'StandardStatus'
            },
            {
              name: 'Pending',
              value: 'Pending',
              type: 'StandardStatus'
            }
          ],
          name: 'Listing Status',
          value: '',
          field: 'StandardStatus',
          type: "radios",
          filterActive: false
        },
        regions: {
          loaded: false,
          mustLoad: true,
          data: [],
          name: 'Region',
          value: '',
          field: 'City',
          type: 'radios',
          action: 'listing_regions',
          filterActive: false
        },
        propertyType: {
          loaded: true,
          mustLoad: false,
          data: [
            {
              name: 'Single Family Detached',
              value: 'Single Family Detached',
              type: 'PropertySubType'
            },
            {
              name: 'Single Family Attached',
              value: 'Single Family Attached',
              type: 'PropertySubType'
            },
            {
              name: 'Fractional Ownership',
              value: 'Fractional Ownership',
              type: 'PropertySubType'
            },
            {
              name: 'Mfg/Mobile Home',
              value: 'Mfg/Mobile Home',
              type: 'PropertySubType'
            },


          ],
          name: 'Property Type',
          value: '',
          field: 'PropertySubType',
          type: 'radios',
          filterActive: false
        },
        // distance:{
        //   loaded: false,
        //   mustLoad: false,
        //   data: [],
        //   name:'Distance',
        //   value:'',
        //   field: 'Distance',
        //   type:'distance',
        //   filterActive:false
        // }
      },
      listingsSortingOptions: [
        {
          option_name: 'Price &uarr;',
          option_value: 'PRICE_ASC'
        },
        {
          option_name: 'Price &darr;',
          option_value: 'PRICE_DESC'
        }
      ],
      mainRegions:[
        'Charleston',
        'Sullivans Island',
        'Isle Of Palms',
        'James Island',
        'West Ashley',
        'North Charleston',
        'Hanahan',
        'Moncks Corner',
        'Hollywood',
        'Goose Creek',
        'Summerville',
        'Ladson'
      ],
      countyRegions:[
        'Charleston County',
        'Berkeley County',
        'Dorchester County',
        'Colleton County'
      ],
      listings: [],
      activeFilters: {},
      hasActiveFilters: false,
      mobileFilterPanelOpen: false,
      pagination: {
        currentPage: 0
      }
    }
  },
  actions: {
    getListings: function () {
      const self = this;
      const url = listings.ajaxUrl;
      const filterData = toRaw(this.activeFilters);

      // reset the loader while we load new data.
      this.$patch({
        listingsLoaded: false
      })

      // make new data object to send to API
      let data = {
        action: 'listings',
        sortBy: self.listingSortBy,
        filters: filterData,
        currentPage: this.pagination.currentPage
      }

      // Make request to backend
      $.ajax({
        method: 'POST',
        url: url,
        data: data,
      }).then(function (resp) {
        const response = JSON.parse(resp);

        if (response.status) {
          self.$patch({
            listings: response.data.listings,
            listingsLoaded: true,
            pagination: response.data.pagination,
            noListingsFoundMsg: response.message
          })
        }
      }).catch(function (err) {
        console.log(err)
      })
    },
    handleHomepageFilters: function () {
      const homeFilters = listings.form;

      if (homeFilters['city']) {
        this.activeFilters['regions'] = {
          id: 'regions',
          name: 'Region',
          value: homeFilters['city'],
          type: 'radios',
          field: 'City'
        };

        this.hasActiveFilters = true;
      }

      if (homeFilters['propertySubType']) {
        this.activeFilters['propertyType'] = ({
          id: 'propertyType',
          name: 'Property Sub Type',
          value: homeFilters['propertySubType'],
          type: 'radios',
          field: 'PropertySubType'
        });

        this.hasActiveFilters = true;
      }

      if (homeFilters['beds']) {
        this.activeFilters['beds'] = ({
          id: 'beds',
          name: 'Beds',
          value: homeFilters['beds'],
          type: 'radios',
          field: 'BedroomsTotal'
        });

        this.hasActiveFilters = true;
      }

      if (homeFilters['baths']) {
        this.activeFilters['baths'] = ({
          id: 'baths',
          name: 'Baths',
          value: homeFilters['baths'],
          type: 'radios',
          field: 'BathroomsFull'
        });

        this.hasActiveFilters = true;
      }

      if (homeFilters['priceMin'] || homeFilters['priceMax']) {

        this.activeFilters['price'] = {
          id: 'price',
          name: 'Price',
          value: {
            min: 0,
            max: 0
          },
          type: 'minmax',
          field: 'ListPrice'
        };

        if (homeFilters['priceMin']) {
          this.activeFilters['price']['value']['min'] = homeFilters['priceMin'];
          this.hasActiveFilters = true;
        }

        if ((homeFilters['priceMax'])) {
          this.activeFilters['price']['value']['max'] = homeFilters['priceMax'];
          this.hasActiveFilters = true;
        }

      }
    },
    getlistingsMapData:function(mapData, cb){
      const self = this;
      const url = listings.ajaxUrl;

      // make new data object to send to API
      let data = {
        action: 'map_listings',
      }

      // SET THE BOUNDS ON AJAX REQUEST
      if('bounds' in mapData){
        data['bounds'] = mapData['bounds'];
      }

      // SET THE BOUNDS ON AJAX REQUEST
      if('center' in mapData){
        data['center'] = mapData['center'];
      }

      if('hasRegion' in mapData.flags){
        data['hasRegion'] = mapData.flags.hasRegion;
        data['region'] = mapData.region;
      }

      // Make request to backend
      $.ajax({
        method: 'POST',
        url: url,
        data: data,
      }).then(function (resp) {
        const response = JSON.parse(resp);

        if (response.status) {
          cb(self, response);
        }
      }).catch(function (err) {
        console.log(err)
      })
    },
    getListingMapContent: function(id, cb){
      const self = this;
      const url = listings.ajaxUrl;

      // make new data object to send to API
      let data = {
        action: 'map_listing_details',
        id: id
      }

      // Make request to backend
      $.ajax({
        method: 'POST',
        url: url,
        data: data,
      }).then(function (resp) {
        const response = JSON.parse(resp);

        if (response.status) {
          cb(self, response);
        }
      }).catch(function (err) {
        console.log(err)
      })
    },
    loadFilterData: function (filterId, filterAction) {
      const self = this;
      const url = wpCore.ajaxUrl;

      if (filterAction) {
        $.ajax({
          method: 'POST',
          url: url,
          data: {
            action: filterAction,
          }
        }).then(function (resp) {
          const response = JSON.parse(resp);

          if (response.status) {
            const data = {
              listingsFilters: {}
            }

            data.listingsFilters[filterId] = {
              loaded: true,
              data: response.data
            }

            self.$patch(data)
          }
        }).catch(function (err) {
          console.log(err)
        })
      }
    },
    updateListingsSort: function (sortBy) {
      this.$patch({
        listingsLoaded: false,
        listingSortBy: sortBy
      });

      this.getListings();
    },
    updateSortingOptions: function () {
      let optionHTML = '';

      const listingsStore = listingStore();
      const options = listingsStore.listingsSortingOptions;
      const activeOption = listingsStore.listingSortBy;

      if (options.length > 0) {
        optionHTML += '<option value="">Sort By...</option>';
        options.forEach(element => {
          if (element.option_value === activeOption) {
            optionHTML += '<option selected value="' + element.option_value + '">' + element.option_name + '</option>';
          } else {
            optionHTML += '<option value="' + element.option_value + '">' + element.option_name + '</option>';
          }
        });
      }

      this.$patch({
        listingSortingOptionsHTML: optionHTML
      })
    },
    updatePaginationOffset: function (currentPage) {
      this.$patch({
        pagination: {
          currentPage: currentPage
        }
      });

      this.getListings();
    },
    applyFilter: function (e, type, id, data) {
      let patchData = {
        activeFilters: this.activeFilters
      }

      if (type && type === 'radios') {
        patchData.activeFilters[id] = {
          id: id,
          name: data.name,
          value: e.target.value,
          type: data.type,
          field: data.field
        }
      }

      if (type && type === 'minmax') {
        let formData = Object.fromEntries(new FormData(e.target));
        patchData.activeFilters[id] = {
          id: id,
          name: data.name,
          value: {
            min: formData[id + 'Min'],
            max: formData[id + 'Max']
          },
          type: data.type,
          field: data.field
        }
      }

      if (!this.isEmpty(this.activeFilters)) {
        patchData.hasActiveFilters = true;
      }

      this.$patch(patchData);
      this.updatePaginationOffset(0);
      this.getListings();
    },
    removeFilter: function (e, id) {
      let patchData = {
        activeFilters: this.activeFilters
      }

      if (patchData.activeFilters[id] !== null) {
        delete patchData.activeFilters[id];
      }

      if (this.isEmpty(this.activeFilters)) {
        patchData.hasActiveFilters = false;
      }

      this.$patch(patchData);

      console.log(this.activeFilters);

      this.updatePaginationOffset(0);
      this.getListings();
    },
    openMobileFilterPanel: function () {
      document.body.classList.add('locked');
      this.$patch({
        mobileFilterPanelOpen: true
      });
    },
    closeMobileFilterPanel: function () {
      document.body.classList.remove('locked');
      this.$patch({
        mobileFilterPanelOpen: false
      });
    },
    isEmpty: function (val) {
      if (val === undefined)
        return true;

      if (typeof (val) == 'function' || typeof (val) == 'number' || typeof (val) == 'boolean' || Object.prototype.toString.call(val) === '[object Date]')
        return false;

      if (val == null || val.length === 0)        // null or 0 length array
        return true;

      if (typeof (val) == "object") {
        // empty object

        var r = true;

        for (var f in val)
          r = false;

        return r;
      }

      return false;
    }
  }
});