import { defineStore } from 'pinia';

export const homepageListingStore = defineStore('homepageListings', {
    state: () => {
        return {
            noListingsFoundMsg: '',
            listingsLoaded: false,
            listings: []
        }
    },
    actions: {
        getActiveListings(max) {
            const self = this;
            const url = wpCore.ajaxUrl;

            $.ajax({
                method: 'POST',
                url: url,
                data: {
                    maxItems: max,
                    action: 'active_listings'
                }
            }).then(function (resp) {
                const response = JSON.parse(resp);

                self.$patch({
                    listings: response.data.listings, 
                    listingsLoaded: true,
                    noListingsFoundMsg: response.message
                })
            }).catch(function () {

            })
        }
    }
})