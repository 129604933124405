<template>
  <div class="ratingsBar">
    <div class="totalRating">
      <h3 class="totalRatingCircle">{{ rating }}</h3>
      <div class="totalRatingStars"><Stars :size="18" :total="5" :filled="rating" /></div>
      <h6>({{ total }}) total</h6>
    </div>
    <div class="ratingBreakdown">
      <div class="ratingStatBar">
        <div class="ratingStatBarStars">
          <Stars :total="5" :filled="5" />
        </div>
        <progress class="progress" :value="fiveStar.total" :max="total"></progress>
        <div class="statTotal">{{ fiveStar.total }}</div>
      </div>
      <div class="ratingStatBar">
        <div class="ratingStatBarStars">
          <Stars :total="5" :filled="4" />
        </div>
        <progress class="progress" :value="fourStar.total" :max="total"></progress>
        <div class="statTotal">{{ fourStar.total }}</div>
      </div>
      <div class="ratingStatBar">
        <div class="ratingStatBarStars">
          <Stars :total="5" :filled="3" />
        </div>
        <progress class="progress" :value="threeStar.total" :max="total"></progress>
        <div class="statTotal">{{ threeStar.total }}</div>
      </div>
      <div class="ratingStatBar">
        <div class="ratingStatBarStars">
          <Stars :total="5" :filled="2" />
        </div>
        <progress class="progress" :value="twoStar.total" :max="total"></progress>
        <div class="statTotal">{{ twoStar.total }}</div>
      </div>
      <div class="ratingStatBar">
        <div class="ratingStatBarStars">
          <Stars :total="5" :filled="1" />
        </div>
        <progress class="progress" :value="oneStar.total" :max="total">{{ oneStar.total }}</progress>
        <div class="statTotal">{{ oneStar.total }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'pinia';

import { reviewsStore } from "../stores/reviews.js";

import Stars from "../components/Stars.vue";

export default {
  name: "ReviewStatistics",
  components: {
    Stars 
  },
  props:{
    skeleton:{
      type: Boolean,
      required: true,
      default: false
    }
  },
  computed: {
    ...mapState(reviewsStore, {
      rating: store => store.ratings.rating,
      total: store => store.ratings.total,
      reviews: store => store.reviews,
      fiveStar: store => store.ratings.fiveStar,
      fourStar: store => store.ratings.fourStar,
      threeStar: store => store.ratings.threeStar,
      twoStar: store => store.ratings.twoStar,
      oneStar: store => store.ratings.oneStar
    }),
  },
  methods: {

  }
};
</script>