<template>
    <div class="starWrapper" v-for="i in [...Array(total).keys()]" :key="i">
        <div class="star star-empty" v-if="(i) >= filled">
            <svg :width="size" :height="size" viewBox="0 0 32 32">
                <use xlink:href="#star" fill="url(#empty)"></use>
            </svg>
        </div>

        <div class="star" v-else>
            <svg :width="size" :height="size" viewBox="0 0 32 32">
                <use xlink:href="#star" fill="url(#full)"></use>
            </svg>
        </div>
    </div>

    <svg style="width: 0; height: 0;" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
        <defs>
            <linearGradient id="empty" x1="0" x2="100%" y1="0" y2="0">
                <stop offset="100%" stop-color="#dcdcdc"></stop>
            </linearGradient>

            <linearGradient id="full" x1="0" x2="100%" y1="0" y2="0">
                <stop offset="100%" stop-color="#fed94b"></stop>
            </linearGradient>

            <symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" id="star">
                <path d="M31.547 12a.848.848 0 00-.677-.577l-9.427-1.376-4.224-8.532a.847.847 0 00-1.516 0l-4.218 8.534-9.427 1.355a.847.847 0 00-.467 1.467l6.823 6.664-1.612 9.375a.847.847 0 001.23.893l8.428-4.434 8.432 4.432a.847.847 0 001.229-.894l-1.615-9.373 6.822-6.665a.845.845 0 00.214-.869z" />
            </symbol>
        </defs>
    </svg>
</template>

<script>
import { mapState } from 'pinia';

export default {
    name: "Stars",
    props: {
        total: {
            type: Number,
            required: true,
            default: 5
        },
        filled: {
            type: Number,
            required: true,
            default: 0
        },
        size: {
            type: Number,
            required: true,
            default: 8
        },
    },
    computed: {

    },
    methods: {

    }
};
</script>

