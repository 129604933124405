import { createApp } from 'vue';
import { createPinia } from 'pinia'

import MainSearchApp from '../../views/MainSearchApp.vue';

export default function loadMainSearch(el) {
    // Create app 
    const app = createApp(MainSearchApp)
    app.use(createPinia())
    app.mount(el);
}