import ListingsApp from '../../vue/apps/homepage/ListingGrid';
import SearchApp   from '../../vue/apps/homepage/MainSearch';
import ReviewsApp  from '../../vue/apps/homepage/Reviews';

(() => {
	'use strict';

	$(document).ready(() => {    
		const listingAppEl = document.getElementById('homepageListingGrid');
		const searchAppEl  = document.getElementById('mainSearchApp');
		const reviewsEl    = document.getElementById('reviewsApp');

		if(listingAppEl){
			new ListingsApp(listingAppEl);
        }

		if(searchAppEl){
			new SearchApp(searchAppEl);
		}

		if(reviewsEl){
			new ReviewsApp(reviewsEl);
		}
	});
})();
