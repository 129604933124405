import { createApp } from 'vue';
import { createPinia } from 'pinia'

import HomepageListingsApp from '../../views/HomepageListingsApp.vue';

export default function loadHomepageListings(el) {
    // Create app 
    const app = createApp(HomepageListingsApp)
    app.use(createPinia())
    app.mount(el);
}