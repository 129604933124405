<template>
  <div class="reviewSliderWrapper">
    <ul :class="getSliderClasses">
      <li v-for="(review, index) in reviews" class="" :key="index">
        <ReviewCard :review="review" />
      </li>
    </ul>
  </div>
</template>

<script>
import { mapState } from 'pinia';
import { reviewsStore } from "../stores/reviews.js";

import { tns } from 'tiny-slider';

import ReviewCard from "../components/ReviewCard.vue";

export default {
  name: "ReviewSlider",
  components:{
    ReviewCard
  },
  mounted(){
    this.loadSlider();
  },
  computed: {
    ...mapState(reviewsStore, {
      rating: store => store.rating,
      total: store => store.total,
      reviews: store => store.reviewData,
    }),
    hasReviews: function(){
      return this.reviews.length > 0;
    },
    getSliderClasses: function () {
      let klasses = 'reviewSlider';

      if (this.hasReviews) {
        klasses += ' hasReviews'
      } else {
        klasses += '';
      }

      return klasses;
    }
  },
  methods: {
    loadSlider: function () {
      const reviewSlider = $('.reviewSlider.hasReviews');

      if(reviewSlider){
        tns({
          container: reviewSlider[0],
          items: 1,
          slideBy: 'page',
          autoplay: true,
          controls: false,
          nav: false,
          speed: 300,
          autoplayTimeout: 5000,
          gutter:30,
          autoplayButtonOutput: false
        });
      }
    }
  }
};
</script>
