import { defineStore } from 'pinia';

export const homeStore = defineStore('homeStore', {
    state: () => {
        return {
            maxActiveListings: home.settings.maxActiveListings,
            listings: []
        }
    },
    actions: {}
});