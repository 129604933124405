<template>
  
  <a href="#" 
     v-if="!isListingFavorited & userAuthenticated" 
     @click.prevent="addToWishlist" 
     title="Add to Favorites">
    <span class="material-symbols-outlined">favorite</span>
    <small v-if="showSubtext">Add to Favorites</small>
  </a>

  <a href="#" 
     class="listingFavoriteIcon" 
     v-if="isListingFavorited && userAuthenticated"
     @click.prevent="removeFromWishlist" 
     title="Add to Favorites">
    <span class="material-symbols-outlined">favorite</span>
    <small v-if="showSubtext">Favorited!</small>
  </a>

  <a href="#" 
     v-if="!userAuthenticated && !isListingFavorited" 
     @click.prevent="showRegisterModal"
     title="Login to Add to Favorites">
    <span class="material-symbols-outlined">favorite</span>
    <small v-if="showSubtext">Add to Favorites!</small>
  </a>

</template>

<script>
import { mapState } from 'pinia';

import { globalStore } from "../stores/global";
import { favoritesStore } from "../stores/favorites";
import { modalStore } from "../stores/modal";

export default {
  name: "FavoriteWishlist",
  components: {},
  props: {
    showSubtext: {
      type: Boolean,
      default: false
    },
    listingID: {
      type: String,
      required: true,
      default: null,
    },
    isListingFavorited: {
      type: Boolean,
      required: true,
      default: false
    }
  },
  data() {
    return {
      isListingFavorited: this.isListingFavorited
    }
  },
  methods: {
    addToWishlist: function () {
      const fs = favoritesStore();
      fs.addToFavorites(this.uid, this.listingID, function (status, resp) {
        //console.log(status, resp)
      });
      this.isListingFavorited = true;
    },
    removeFromWishlist: function () {
      const fs = favoritesStore();
      fs.removeFavorite(this.uid, this.listingID, function (status, resp) {
        //console.log(status, resp)
      });
      this.isListingFavorited = false;
    },
    showRegisterModal: function () {
      const ms = modalStore();

      ms.$patch({
        flags: {
          isModalContentLoaded: true,
          isModalFullscreen: false
        },
        content: {
          id: 'createAccountCTA',
          title: 'Create an Account',
          bodyContent: '<div class="alignCenter"><p>Adding a listing to your favorites requires an account; Create one here!</p><br /><a href="/login" class="btn btn-primary btn-large" target="_blank">Register Now</a></div>'
        }
      })

      ms.launchModal();
    }
  },
  computed: {
    ...mapState(globalStore, {
      uid: store => store.user.uid,
      userAuthenticated: store => store.userAuthenticated
    }),
  }
}
</script>

<style scoped>
</style>
