import { defineStore } from 'pinia';

export const modalStore = defineStore('modalStore', {
  state: () => {
    return {
      flags: {
        isModalOpen: false,
        isModalFullscreen: false,
        isModalContentLoaded: false
      },
      content: {
        id: null,
        title: null,
        bodyContent: null
      }
    }
  },
  actions: {
    launchModal: function (cb) {
      document.body.classList.add('locked');

      this.$patch({
        flags: {
          isModalOpen: true,
        }
      });

      if(typeof cb == 'function'){
        cb();
      }
    },
    closeModal: function () {
      const modal = modalStore();

      this.$patch({
        flags: {
          isModalOpen: false,
          isModalFullscreen: false,
          isModalContentLoaded: false
        },
        content:{
          id: null,
          title:null,
          bodyContent:null
        }
      });

      document.body.classList.remove('locked');
    }
  }
});