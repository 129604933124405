<template>
    
    <form class="inline" method="get" action="/listings">

        <div class="listingSearchRegion">
            <label class="acc" for="region">City:</label>
            <select id="region" name="city">
                <option value="">City</option>
                <option v-for="region in regions.data" :value="region.value">{{ region.name }}</option>
            </select>
        </div>

        <div class="listingSearchPropertyType">
            <label class="acc" for="propertyType">Property Type:</label>
            <select id="propertyType" name="propertySubType">
                <option value="">Property Type</option>
                <option v-for="type in propertyType.data" :value="type.value">{{ type.name }}</option>
            </select>
        </div>

        <div class="mobileFormSmusher">
            <div class="listingSearchBeds">
                <label class="acc" for="beds">Beds:</label>
                <input id="beds" class="short" type="number" min="0" name="beds" placeholder="Beds" />
            </div>

            <div class="listingSearchBaths">
                <label class="acc" for="baths">Baths:</label>
                <input id="baths" class="short" type="number" min="0" name="baths" placeholder="Baths" />
            </div>
        </div>

        <div class="mobileFormSmusher">
            <div class="listingSearchMinPrice">
                <label class="acc" for="minPrice">Minimum Price:</label>
                <input id="minPrice" class="short" type="number" min="0" name="priceMin" placeholder="Min $" />
            </div>

            <div class="listingSearchMaxPrice">
                <label class="acc" for="maxPrice">Max Price:</label>
                <input id="maxPrice" class="short" type="number" min="0" name="priceMax" placeholder="Max $" />
            </div>
        </div>

        <div class="listingSearchSubmit"><button class="btn btn-secondary btn-large"><span class="material-symbols-outlined">search</span></button></div>
    </form>

</template>

<script>
import { mapState } from 'pinia';

import { globalStore }  from "../stores/global.js";
import { listingStore } from "../stores/listings.js";

export default {
    name: "MainSearchApp",
    components: {},
    props: {},
    mounted() {
        const ls = listingStore();
        const region = this.regions;

        ls.loadFilterData('regions', this.regions.action);
    },
    computed: {
        ...mapState(globalStore, {
            uid: store => store.uid,
            isUserAuthenticated: store => store.isUserAuthenticated,
            siteKey: store => store.siteKey
        }),
        ...mapState(listingStore, {
            regions:        store => store.listingsFilters.regions,
            propertyType:   store => store.listingsFilters.propertyType,
            listingsLoaded: store => store.listingsLoaded
        })
    },
};
</script>

<style>
</style>
